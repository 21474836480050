.layer-toggle {
  position: absolute;
  right: 0;
  top: 0;
  margin-top: 4.5rem;
  margin-right: 1rem;
  width: 8rem;
}

.layer-toggle--all,
.layer-toggle--linked {
  background: #fff;
  width: 100%;
  padding: 0.8rem 0;
  border-radius: 0.4rem 0.4rem 0 0;
  border: 1px solid rgba(0, 0, 0, 0.4);
  font-family: Arial, Helvetica, sans-serif;
  font-size: 1rem;
  text-align: center;
  font-weight: 600;
}

.layer-toggle--all {
  top: 1rem;
  border-radius: 0.4rem 0.4rem 0 0;
}

.layer-toggle--linked {
  top: 3.83rem;
  border-radius: 0 0 0.4rem 0.4rem;
  border-top: 0;
}

.layer-toggle--all:hover,
.layer-toggle--linked:hover {
  background-color: #3887be;
  color: #ffffff;
}
