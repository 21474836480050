@import url('../../../../../node_modules/accessible-autocomplete/dist/accessible-autocomplete.min.css');

.searchBox {
  position: absolute;
  top: .5rem;
  left: .5rem;
  right: .5rem;
  background-color: white;
}

@media (min-width: 50rem) {
  .searchBox {
    top: 1rem;
    /* width: 30%; */
    left: 19rem;
    right: 1rem;
    max-height: 80%;
  }  
}

.searchBox label {
  display: none;
}